import Vue from "vue";
import App from "./App.vue";
import router from "./router";

Vue.config.productionTip = false;
// element ui
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// Vue.use(ElementUI, { size: "small" });
Vue.use(ElementUI);

// lazyload
import VueLazyLoad from "vue-lazyload";
Vue.use(VueLazyLoad);

// vue-photo-preview
import VuePhotoPreview from "vue-photo-preview";
import "vue-photo-preview/dist/skin.css";
Vue.use(VuePhotoPreview, {
  tapToToggleControls: false, //点击图像切换工具栏的可见性
  fullscreenEl: false, //控制是否显示右上角全屏按钮
  // tapToClose: true, //点击滑动区域应关闭图库
  // shareEl: false, //控制是否显示分享按钮
  zoomEl: false, //控制是否显示放大缩小按钮
  bgOpacity: 0.85,
  preload: [1, 3], // 预加载数组
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");

import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "",
    redirect: "login",
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/LoginPage.vue"),
    meta: {
      title: "登录",
    },
  },
  {
    path: "/activity",
    name: "activity",
    component: () => import("@/views/ActivityPage.vue"),
    meta: {
      title: "活动管理",
    },
  },
  {
    path: "/filter",
    name: "filter",
    component: () => import("@/views/FilterPage.vue"),
    meta: {
      title: "相册选择",
    },
  },
  {
    path: "/photo/:id/:title?",
    name: "photo",
    component: () => import("@/views/PhotoPage.vue"),
    meta: {
      title: "视界云图",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;

<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "App",
}
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
ul,
li {
  list-style: none;
  outline: none;
}
a {
  text-decoration: none;
}
img {
  vertical-align: middle;
  border: 0;
}
html,
body {
  height: 100%;
  background: #f4f7fe;
}
#app {
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: transparent;
  // text-align: center;
  color: #202020;
  height: 100%;
}
.page {
  padding: 16px;
  height: 100%;
}

.toolBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.tool-right {
  display: flex;
  align-items: center;
  // .el-checkbox + .el-button {
  //   margin-left: 16px;
  // }
}
// loading
.el-loading-mask {
  background-color: rgba(255, 255, 255, 0.8) !important;
}
// 分页
.el-pagination.is-background {
  text-align: center;
  padding: 16px 24px;
}
// dialog
.el-dialog {
  width: 60%;
  max-height: 95vh;
  margin-top: 0 !important;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  .el-dialog__header {
    flex: none;
    // height: 48px;
    padding: 6px 20px;
    box-sizing: border-box;
    border-bottom: 1px solid #ebeef5;
    // box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  }
  .el-dialog__headerbtn {
    font-size: 20px;
    width: 24px;
    height: 24px;
    top: 12px;
    right: 12px;
  }
  .el-dialog__title {
    line-height: 36px;
    font-size: 16px;
  }
  .el-dialog__body {
    padding: 16px 20px;
    height: 95vh;
    overflow: auto;
    flex: 1;
  }
  .el-dialog__footer {
    flex: none;
    text-align: center;
  }
}

.el-message.custom-message {
  background: rgba(0, 0, 0, 0.5);
  border: none;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%);
  color: #fff;
  min-width: auto;
  .el-message__icon {
    display: none;
  }
  .el-message__content {
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 24px;
    text-align: center;
    white-space: nowrap;
    b {
      font-size: 16px;
      margin-bottom: 6px;
    }
    span {
      font-size: 14px;
    }
  }
}
</style>

